<template>
<div class="nation">
  <top-bar :left="true"></top-bar>
  <van-index-bar :sticky="false">
    <van-index-anchor :index="item.initial"  v-for="item in alphabet" :key="item.initial">
      <p class="p">{{item.initial}}</p>
      <van-cell :title="item2.label" v-for="item2 in item.cells" :key="item2.value" @click="onClick(item2)"/>
    </van-index-anchor>
  </van-index-bar>
</div>
</template>

<script>
import {getDictTree} from '@/utils/common'
import topBar from '@/components/topBar/topBar.vue'

import { mapMutations } from 'vuex'

export default {
  components: { topBar },
  data() {
    return {
      dictList:[],
      alphabet:[]
    }
  },
  methods: {
    ...mapMutations,
    getDict() {
        this.$http({
    url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
    method: 'post',
    params: this.$http.adornParams({
      code:'nation',
    },false)
  }).then(({data})=> {
    if (data.code == 0) {
        for (let i in data.dicts) {
            let dict = data.dicts[i];
            dict["label"] = dict.label;
            dict["value"] = dict.value + "";
        }
        this.dictList = data.dicts
        this.info()
    }
  })
    },
    info() {
      const alphabet = []
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').forEach((initial) => {
          const cells = this.dictList.filter((ren) => ren.parameter.charAt(0).toUpperCase() === initial)
          alphabet.push({
              initial,
              cells,
          })
      })
      this.alphabet = alphabet
      console.log(this.alphabet);
    },
    onClick(value) {
      this.$emit('setNation', value)
    }
  },
  created() {
    this.getDict()
  }
}
</script>

<style lang="scss" scoped>
  .nation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: #f5f5f5;
    overflow: scroll;
    .p {
      text-align: center;
      background-color: #ccc;
      padding: 0;
      height: 50px;
      line-height: 50px;
      font-size: 30px;
    }
  }
</style>
